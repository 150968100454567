export { default as HighHeelsImage } from './high_heels.png';
export { default as ChevronCircleIcon } from './icons/chevron-circle.svg';
export { default as ChevronIcon } from './icons/chevron.svg';
export { default as CloseIcon } from './icons/close.svg';
export { default as HomeIcon } from './icons/home.svg';
export { default as SuccessIcon } from './icons/success.svg';

export { default as BusinessMeetingImage } from './business_meeting.png';
export { default as CreamSilkBackground } from './cream_silk_background.webp';
export { default as HeroMain } from './hero_main.webp';
export { default as KidsSeminarImage } from './kids-seminar.png';
export { default as IconLogo } from './logo/icon.svg';
export { default as WordmarkLogo } from './logo/wordmark.svg';
export { default as WordmarkSubtextLogo } from './logo/wordmark_sub.svg';
export { default as MeCreamCoat } from './me_cream_coat.webp';
export { default as SilkCreamFolds } from './silk_cream_folds.webp';
export { default as VelvetBackground } from './velvet_background.webp';
